import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { Send, Loader, ThumbsUp, ThumbsDown, RotateCcw, Search } from 'lucide-react'; // Replaced Query with Search

import { Input } from './ui/input';
import { Button } from './ui/button';
import { QueryButton } from './ui/query_button';
import { StatsAndAiInfoTooltip, QueryInfoTooltip } from './ui/tooltips';
import { Alert, AlertDescription } from './ui/alert';
// import { Textarea } from './ui/textarea';

import { UI_STRINGS } from './ui/constants';

const socket = io('https://onlystatsfans.com:9001'); // TODO fix this -- Ec2 pub ip: 50.18.98.156

const CollapsibleQuery = ({ sqlQuery, formattedQuery }) => {

  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="space-y-2">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <p className="font-medium flex-1">SQL Query:</p>
        <span className="text-sm text-gray-400">
          {isCollapsed ? '[ + ]' : '[ - ]'}
        </span>
      </div>
      {!isCollapsed && (
        <pre
          className="font-mono bg-black p-3 rounded text-xs whitespace-pre-wrap"
          dangerouslySetInnerHTML={{ __html: formattedQuery }}
          data-original-query={sqlQuery}
        />
      )}
    </div>
  );
};

const CollapsibleQueryResult = ({ title, columns, rows }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className="space-y-2">
      <div
        className="flex items-center cursor-pointer"
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <p className="font-medium flex-1">{title}:</p>
        <span className="text-sm text-gray-400">
          {isCollapsed ? '[ + ]' : '[ - ]'}
        </span>
      </div>
      {!isCollapsed && (
        <div className="overflow-x-auto rounded-lg bg-[#1E1E1E] p-4">
          {rows.length === 0 ? (
            <p className="text-sm text-gray-300">No results found.</p>
          ) : (
            <table className="w-full">
              <thead className="font-verdana">
                <tr>
                  <th className="border-b-2 border-gray-600 px-4 py-2 text-left text-sm font-bold text-gray-200"></th>
                  {columns.map((col, index) => (
                    <th
                      key={index}
                      className="border-b-2 border-gray-600 px-4 py-2 text-left text-sm font-bold text-gray-200"
                      style={{ fontSize: '12px' }}
                    >
                      {col}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex} className="hover:bg-gray-900/50 transition-colors">
                    <td className="border-b border-gray-600 px-4 py-2 text-left text-sm text-gray-300">
                      {rowIndex + 1}
                    </td>
                    {columns.map((col, colIndex) => (
                      <td
                        key={colIndex}
                        className="border-b border-gray-600 px-4 py-2 text-sm text-gray-300"
                      >
                        {typeof row[col] === 'boolean' ? (row[col] ? 'YES' : 'NO') : row[col]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

const exampleQueries = [
  {
    text: "What players in NBA history have achieved a single-season average of at least 18 points, 5 rebounds, 5 assists, 1.5 stocks (steals + blocks), with a 3-point shooting percentage of 40% or higher?",
    icon: <Search className="w-4 h-4 mr-2 flex-shrink-0" />, // Added flex-shrink-0
  },
  {
    text: "Who had the highest field goal percentage in the 2022 season among players who took at least 100 shots and had a 3 point shooting percentage of at least 37%?",
    icon: <Search className="w-4 h-4 mr-2 flex-shrink-0" />,
  },
  {
    text: "What is the average points per game for the top 20 players in total points scored over the last 5 seasons? Include the player names and shooting % stats in the results.",
    icon: <Search className="w-4 h-4 mr-2 flex-shrink-0" />,
  },
  {
    text: "Top 10 players by 3P% in 2023-24 season. Show all players general stats for the season, too",
    icon: <Search className="w-4 h-4 mr-2 flex-shrink-0" />,
  },
  {
    text: "Who had the highest field goal percentage in the 2022 season among players who took at least 100 shots? Show top 10",
    icon: <Search className="w-4 h-4 mr-2 flex-shrink-0" />,
  },
  {
    text: "Show players from the 2023-2024 regular season where everyone took at least 100 3 point shots and had a 3 point percentage of at least 37%. Order results by 3 point percentage in descending order and limit results to 10 players.",
    icon: <Search className="w-4 h-4 mr-2 flex-shrink-0" />
  }
];
const ChatInterface = () => {
  const [query, setQuery] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [queryHistory, setQueryHistory] = useState([]); // Store previous queries
  const [historyIndex, setHistoryIndex] = useState(-1); // Track the current index in history
  const [feedback, setFeedback] = useState({});  // Format: { messageIndex: { liked: boolean, disliked: boolean } }

  const chatEndRef = useRef(null);
  const inputRef = useRef(null); // Create a ref for the input

  useEffect(() => {
    inputRef.current?.focus();

    socket.on('partial_response', (data) => {
      let content;
      if (data.type === 'query_result') {
        const rows = data.data;
        const columns = Object.keys(rows[0] || {});

        const defaultTitle = "Query result";
        const stats_table_title = data.title;

        content = (
          <CollapsibleQueryResult
            title={stats_table_title ? stats_table_title : defaultTitle}
            columns={columns}
            rows={rows}
          />
        );
        setIsLoading(false);
      } else if (data.type === 'generated_sql_query') {
        const sqlQuery = data.data;

        const formattedQuery = sqlQuery
          .replace(/(".*?")/g, '<span style="color: orange;">$1</span>') // String literals - Orange
          .replace(
            /\b(FROM|WHERE|ORDER\s+BY|GROUP\s+BY|LIMIT|JOIN|ON|HAVING|AS|AND|OR|NOT|IN|IS|NULL|BETWEEN|EXISTS|LIKE|DISTINCT|CASE|WHEN|THEN|ELSE|END)\b/gi,
            '\n<span style="color: lightblue;">$1</span>'
          )
          .replace(
            /\bSELECT\b/gi,
            '<span style="color: lightblue;">SELECT</span>'
          ) // SELECT keyword - Light blue without newline
          .replace(
            /\b(CAST|COUNT|SUM|AVG|MIN|MAX)\b/gi,
            '<span style="color: yellow;">$1</span>'
          ) // Function names - Yellow
          .replace(
            /\b(NUMERIC|VARCHAR|INT|INTEGER|DATE|DECIMAL|FLOAT|CHAR|TEXT)\b/gi,
            '<span style="color: green;">$1</span>'
          ) // Data types - Green
          .replace(/(\b\d+\b)/g, '<span style="color: purple;">$1</span>') // Numeric values - Purple
        // Table/column names will remain the default text color (light gray)

        content = (
          <CollapsibleQuery
            sqlQuery={sqlQuery}
            formattedQuery={formattedQuery}
            autoCollapse={true}  // Add this prop
          />
        );
      } else {
        content = `${data.type}: ${data.data}`;
      }
      setChatHistory(prev => [...prev, { type: 'bot', content }]);
    });

    return () => {
      socket.off('partial_response');
    };
  }, []);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatHistory]);

  const handleSubmit = (e) => {
    if (e) { e.preventDefault(); }

    if (!query.trim()) {
      return;
    }

    setIsLoading(true);
    setChatHistory(prev => [...prev, { type: 'user', content: query }]);
    setError(null);
    setQueryHistory(prev => [query, ...prev]); // Add the current query to history
    setHistoryIndex(-1); // Reset history index

    socket.emit('query', { query });
    setQuery('');
  };

  const handleExampleQueryClicked = (example_query) => {
    if (!example_query.trim()) {
      return;
    }

    setIsLoading(true);
    setChatHistory(prev => [...prev, { type: 'user', content: example_query }]);
    setError(null);
    setQueryHistory(prev => [example_query, ...prev]); // Add example query to history
    setHistoryIndex(-1); // Reset history index

    socket.emit('query', { query: example_query });
    setQuery('');
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowUp') {
        e.preventDefault(); // Prevent default behavior
        if (historyIndex < queryHistory.length - 1) {
          setHistoryIndex(prevIndex => prevIndex + 1);
          setQuery(queryHistory[historyIndex + 1]);
        }
      } else if (e.key === 'ArrowDown') {
        e.preventDefault(); // Prevent default behavior
        if (historyIndex > 0) {
          setHistoryIndex(prevIndex => prevIndex - 1);
          setQuery(queryHistory[historyIndex - 1]);
        } else if (historyIndex === 0) {
          setHistoryIndex(-1);
          setQuery('');
        }
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [queryHistory, historyIndex]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Insert newline at cursor position
        e.preventDefault();
        const { selectionStart, selectionEnd } = e.target;
        const newValue = query.substring(0, selectionStart) + '\n' + query.substring(selectionEnd);
        setQuery(newValue);
        // Move cursor to the next line
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = selectionStart + 1;
        }, 0);
      } else {
        // Submit the form
        e.preventDefault();
        handleSubmit();
      }
    }
  };

  const sendFeedbackToServer = (messageIndex, isPositive) => {
    console.log("sendFeedbackToServer");

    // Find the current message and its preceding message
    const currentMessage = chatHistory[messageIndex];
    const precedingMessage = chatHistory[messageIndex - 1];

    const responseType = currentMessage.content.props?.children?.[0]?.props?.children.props?.children;
    const precedingType = precedingMessage.content.props?.children?.[0]?.props?.children.props?.children;

    // TODO: FIX THIS BULLSHIT
    // console.log(currentMessage);
    // console.log(precedingMessage);
    // console.log(responseType);
    // console.log(precedingType);

    if (responseType === "Interpretation:") {
      const response = {
        response_type: responseType,
        content: currentMessage.content.props?.children?.[1]?.props?.children.props?.children,
        preceding_content: precedingMessage.content,
        feedback: isPositive ? 'positive' : 'negative'
      };
      console.log(response);
      socket.emit('training_data', response);
      return;
    } else if (responseType === "SQL Query:") {
      // Get the original SQL query from the data attribute
      const originalQuery = currentMessage.content.props?.children?.[1]?.props?.['data-original-query'];
      const response = {
        response_type: responseType,
        content: originalQuery, // Send the original query string
        preceding_content: precedingMessage.content,
        feedback: isPositive ? 'positive' : 'negative'
      };
      console.log(response);
      socket.emit('training_data', response);
      return;
    } else if (precedingType && precedingType === "SQL Query:") {
      const originalQuery = precedingMessage.content.props?.children?.[1]?.props?.['data-original-query'];
      const response = {
        response_type: "Query Result",
        content: "LONG", // currentMessage.content.props?.children?.[1]?.props?.children.props?.children,
        preceding_content: originalQuery,
        feedback: isPositive ? 'positive' : 'negative'
      };
      console.log(response);
      socket.emit('data_quality', response);
      return;
    }
  };

  const handleThumbsUp = (index) => {
    console.log("Thumbs up clicked");
    setFeedback(prev => ({
      ...prev,
      [index]: {
        liked: !prev[index]?.liked,
        disliked: false
      }
    }));

    // Only send feedback if it's being turned on (not off)
    if (!feedback[index]?.liked) {
      sendFeedbackToServer(index, true);
    }
  };

  const handleThumbsDown = (index) => {
    console.log("Thumbs down clicked");
    setFeedback(prev => ({
      ...prev,
      [index]: {
        liked: false,
        disliked: !prev[index]?.disliked
      }
    }));

    // Only send feedback if it's being turned on (not off)
    if (!feedback[index]?.disliked) {
      sendFeedbackToServer(index, false);
    }
  };

  const handleRetry = (messageIndex) => {
    // Find the current message and its preceding message
    const currentMessage = chatHistory[messageIndex];
    const precedingMessage = chatHistory[messageIndex - 1];

    const responseType = currentMessage.content.props?.children?.[0]?.props?.children.props?.children;

    // Remove all messages after the current message
    setChatHistory(prev => prev.slice(0, messageIndex));
    setIsLoading(true);

    if (responseType === "Interpretation:") {
      const response = {
        response_type: responseType,
        content: currentMessage.content.props?.children?.[1]?.props?.children.props?.children,
        preceding_content: precedingMessage.content,
        feedback: 'negative'
      };
      console.log(response);
      socket.emit('retry', response);
      return;
    } else if (responseType === "SQL Query:") {
      // Get the original SQL query from the data attribute
      const originalQuery = currentMessage.content.props?.children?.[1]?.props?.['data-original-query'];
      const response = {
        response_type: responseType,
        content: originalQuery, // Send the original query string
        preceding_content: precedingMessage.content,
        feedback: 'negative'
      };
      console.log(response);
      socket.emit('retry', response);
      return;
    }

    // TODO: Actually retry the query on the backend and handle the response from the server here while erasing subsequent messages and spinning!
  };

  return (
    <div className="flex flex-col flex-1 max-w-3xl mx-auto p-4">
      <div className="flex-1 overflow-y-auto mb-4 space-y-4">
        {chatHistory.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <h3 className="text-xl font-semibold mb-2">
              🏀  StatMuseHater
              <span className="align-middle text-white bg-gray-600 text-xs px-1 pt-1.4 pb-1.4">
                BETA
              </span>
            </h3>
            <StatsAndAiInfoTooltip />
            <QueryInfoTooltip />
            <div className="space-y-2">
              {exampleQueries.map((example, index) => (
                <QueryButton
                  key={index}
                  onClick={() => handleExampleQueryClicked(example.text)}
                  className="w-full min-h-[64px] mt-4 px-4 border border-gray-400 rounded-lg shadow-md hover:bg-gray-500 cursor-pointer flex items-center"
                  style={{ fontSize: 'clamp(12px, 14px, 16px)' }}
                >
                  <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center">
                    {example.icon}
                  </div>
                  <span className="line-clamp-3">{example.text}</span>
                </QueryButton>
              ))}

            </div>
          </div>
        )}
        {chatHistory.map((message, index) => (
          <div key={index} className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}>
            <div className="relative">
              <div className={`max-w-lg md:max-w-xl p-3 rounded ${message.type === 'user' ? 'bg-blue-500 text-white' : 'bg-gray-600 text-white'
                }`} style={{ fontFamily: 'system-ui', fontWeight: 400, fontSize: 14 }}>
                {(
                  typeof message.content === 'string' ? message.content : message.content
                )}
              </div>

              {message.type === 'bot' && (
                <div className="flex space-x-2 justify-end mt-1">
                  <button
                    onClick={() => handleThumbsUp(index)}
                    className={`p-1 hover:bg-gray-700 rounded transition-colors ${feedback[index]?.liked ? 'text-green-400' : 'text-gray-400'
                      }`}
                  >
                    <ThumbsUp className="w-4 h-4" />
                  </button>
                  <button
                    onClick={() => handleThumbsDown(index)}
                    className={`p-1 hover:bg-gray-700 rounded transition-colors ${feedback[index]?.disliked ? 'text-red-400' : 'text-gray-400'
                      }`}
                  >
                    <ThumbsDown className="w-4 h-4" />
                  </button>
                  {message.content.props?.children?.[0]?.props?.children === 'SQL Query:' && (
                    <>
                      <button
                        onClick={() => handleRetry(index)}
                        className="p-1 hover:bg-gray-700 rounded transition-colors text-gray-400 hover:text-yellow-400"
                      >
                        <RotateCcw className="w-4 h-4" />
                      </button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="relative flex items-center mt-auto">
        <Input
          ref={inputRef}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder={UI_STRINGS.chatboxPlaceholderText}
          className="flex-grow text-lg h-12 pr-12"
        />
        <Button
          type="submit"
          disabled={isLoading}
          className="absolute right-1 hover:bg-gray-500 flex items-center justify-center"
        >
          {isLoading ? (
            <Loader className="animate-spin w-6 h-6 text-white hover:text-gray-300" />
          ) : (
            <Send className="w-6 h-6 text-white hover:text-gray-300" />
          )}
        </Button>
      </form>
    </div>
  );
};

export default ChatInterface;